/* src/components/Login.css */

.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(to right, #6a11cb, #2575fc);
    font-family: 'Arial', sans-serif;
  }
  
  .login-box {
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
    text-align: center;
  }
  
  .login-title {
    font-size: 1.5rem;
    color: #333333;
    margin-bottom: 1.5rem;
  }
  
  .input-group {
    margin-bottom: 1rem;
    text-align: left;
  }
  
  .input-group label {
    display: block;
    font-weight: bold;
    color: #555555;
    margin-bottom: 0.5rem;
  }
  
  .input-group input {
    width: 100%;
    padding: 0.75rem;
    border-radius: 5px;
    border: 1px solid #dddddd;
    font-size: 1rem;
  }
  
  .input-group input:focus {
    outline: none;
    border-color: #2575fc;
  }
  
  .login-button {
    width: 100%;
    padding: 0.75rem;
    border-radius: 5px;
    border: none;
    background-color: #2575fc;
    color: #ffffff;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .login-button:hover {
    background-color: #1a5dbb;
  }
  
  .error-message {
    color: #ff4d4d;
    margin-bottom: 1rem;
  }
  