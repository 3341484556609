.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* src/App.css */

.content-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
}

.job-list {
  list-style: none;
  padding: 0;
}

.job-item {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;
  position: relative;
  background-color: #f9f9f9;
  width: 400px;
    float: left !important;
    text-align: left;
    display: inline;
}

.job-item.applied {
  background-color: #e0ffe0;
}

.applied-label {
  color: green;
  font-weight: bold;
}

.job-item h3 {
  margin: 0 0 0.5rem 0;
}

.job-item p {
  margin: 0.5rem 0;
}

.job-item button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
}

.job-item button:hover {
  background-color: #0056b3;
}
.job-image{
  width: 200px;
  height: 200px;
  border-radius: 10px;
  margin-right: 1rem;
  float: left;
  overflow: hidden;
}

.job-image img{
  width: 100%;
  height: 100%;
  object-fit: cover;
} 